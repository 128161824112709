


































































import { Component } from 'vue-property-decorator';
import ClassSeatingToolbar from './ClassSeatingToolbar.vue';

@Component
export default class ClassSeatingBottomToolbar extends ClassSeatingToolbar {
}
